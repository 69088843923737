<template>
  <CookieControl :locale="computedLocale">
    <template #bar>
      <h2>{{ $cookies.localeTexts[computedLocale]?.bannerTitle }}</h2>
      <p><strong>{{ title }}</strong> <span>{{ $cookies.localeTexts[computedLocale]?.bannerDescription }}</span></p>
    </template>
  </CookieControl>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n, useCookieControl, useRuntimeConfig } from '#imports';
import type { Locale } from '@dargmuesli/nuxt-cookie-control/runtime/types';

const { locale } = useI18n();
const { moduleOptions: $cookies } = useCookieControl();
const { DEFAULT_LOCALE } = useRuntimeConfig().public;

interface Props {
  title: string
  defaultLocale?: Locale
}

const props = withDefaults(defineProps<Props>(), {
  defaultLocale: undefined,
});

const computedDefaultLocale = computed(() => props.defaultLocale || DEFAULT_LOCALE as Locale);
const computedLocale = computed(() => locale.value as Locale || computedDefaultLocale.value);
</script>
